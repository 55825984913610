import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import title_image from '../../images/titles/enterprise_applications_and_solutions.jpg'
import person from '../../images/person.png'

const pageTitle = "Enterprise Applications and Solutions";
const pageSlug = "enterprise-applications-and-solutions";
class CloudComputing extends React.Component{
  render(){
    return(
      <Layout pageType="technology-services">
      <SEO title={pageTitle} />
      <section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">{pageTitle}</h1>
            <h6 className="subtitle">We offer cloud computing services for all the steps of your journey.</h6>
        </div>
      </section>

      <section id="p_technology_services" className="section static-page page-content">
        <div className="container">
            <div className="columns">
                <div className="column is-12 content-area">
                    <h3>
                    By combining strategic insights with highly skilled and hands-on ERP & CRM resources,
                     your business can create value today.
                    </h3>
                    <p>It’s well known that companies struggle to achieve the business benefits they expected 
                        from their ERP & CRM solutions.</p>
                    <p>Failed implementations upgrades occur far too often. These situations can create an unnecessary 
                        financial and organizational strain on businesses. Our experts understand all the potential 
                        pitfalls that come with these mission-critical and high-investment projects. We bring proven 
                        methodologies and experience that allow for unique collaboration capabilities to our clients 
                        that ensure success.</p>
                    <p>Whether it’s your first time undergoing implementation or you’re just performing an 
                        upgrade, we can help.</p>
                     <p>Not sure where to start? Together we can figure that out.</p>
                   <Link to="/contact-us" className="button green">Contact us</Link>
                   <span className="break"></span>
                    <h4>Our Multi-Dimensional Enterprise Applications and Solution Packages</h4>
                    <p>Our experts have experience in packaged software consulting and a long track record of delivering 
                        successful projects. Our expertise in ERP and CRM is complemented by our first-class industry, 
                        business, and IT solutions.</p>
                    <span className="break"></span>
                    <h4>Implementation Services</h4>
                    <p>We understand that ERP and CRM projects are business projects, not just technology projects. 
                        Enterprise solution projects can fail when change management is not incorporated correctly. 
                        This results in a lack of adoption and a low return on investment. Technology can enable change 
                        in your organization.</p>
                        <span className="break"></span>
                    <h4>Advisory Services</h4>
                    <p>We have the experience that is needed to work with you and become your trusted advisor. We 
                        are flexible, transparent and always strive to do the right thing for our clients. We can 
                        provide advice on:</p>
                    <ul>
                        <li>Program and Project Management</li>
                        <li>CRM & ERP implementation planning</li>
                        <li>Enterprise System analysis and reports</li>
                        <li>Advantages and Disadvantages of on-premise and cloud strategy solutions</li>
                        <li>IT strategy and Roadmap Development</li>
                    </ul>
                    <span className="break"></span>
                    <h4>Supplemental Staffing</h4>
                    <p>We can provide you with staffing to supplement your projects and provide experienced project 
                        management assistance and architecture advice that ensure your projects stay on track. We 
                        employ experienced professionals who can help with your mission-critical projects by assisting 
                        your:</p>
                        <ul>
                            <li>Application Architects</li>
                            <li>Functional Analysts</li>
                            <li>Program and Project Managers</li>
                            <li>Developer and Database Administrators</li>
                        </ul>
                    <span className="break"></span>
                    <h4>Technical Services</h4>
                    <p>Our EAS technologists are constantly being confronted with tricky situations because companies tend 
                        to under-invest in maintaining their infrastructure and databases. However, our team is well-versed in 
                        diagnosing your operating system quickly for integration and database services. Also, they can put a 
                        realistic plan in motion to get your systems back online and implement those solutions.</p>
                    <ul>
                        <li>Data Migrations</li>
                        <li>Technical Troubleshooting</li>
                        <li>Operating System Migration</li>
                        <li>Database Health Checks</li>
                        <li>Patching and Release Management</li>
                    </ul>
                  
                </div>
               {/* <div className="column is-3 sidebar">
                    <div className="person-info">
                        <img src={person} />
                        <div className="inner">
                            <h2 className="name">Greg Klem</h2>
                            <p className="status">Vice President</p>
                            <p>National Practice Lead - Business </p>
                            <p>Consulting Services</p>
                        </div>
                        <p>Not sure where to start, our team is always ready to help.</p>
                        <Link to="/" className="button green">Talk with a professional</Link>
                    </div>
                </div>*/}
            </div>
        </div>
      </section>
      </Layout>
    )
  }
}

export default CloudComputing
